import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "kelompokList";
const modelPath = "/anggota/tag/";

class KelompokList extends ListModelBase {
  constructor() {
    super(modelName, modelPath);
  }
}

export default KelompokList;